var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("c-tab", {
    attrs: {
      type: "horizon",
      dense: true,
      tabItems: _vm.tabItems,
      inlineLabel: true,
      height: _vm.tabHeight,
    },
    on: {
      "update:tabItems": function ($event) {
        _vm.tabItems = $event
      },
      "update:tab-items": function ($event) {
        _vm.tabItems = $event
      },
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (tab) {
          return [
            _c(tab.component, {
              tag: "component",
              attrs: {
                popupParam: _vm.popupParam,
                dangerStore: _vm.dangerStore,
                isOld: _vm.isOld,
                contentHeight: _vm.contentHeight,
              },
              on: {
                "update:contentHeight": function ($event) {
                  _vm.contentHeight = $event
                },
                "update:content-height": function ($event) {
                  _vm.contentHeight = $event
                },
                getDetail: _vm.getDetail,
                closePopup: _vm.closePopup,
              },
            }),
          ]
        },
      },
    ]),
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v
      },
      expression: "tab",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }