<template>
  <c-tab
    type="horizon"
    :dense="true"
    :tabItems.sync="tabItems"
    :inlineLabel="true"
    :height="tabHeight"
    v-model="tab"
  >
    <template v-slot:default="tab">
      <component
        :is="tab.component"
        :popupParam="popupParam"
        :dangerStore="dangerStore"
        :isOld="isOld"
        :contentHeight.sync="contentHeight"
        @getDetail="getDetail"
        @closePopup="closePopup"
      />
    </template>
  </c-tab>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'dangerMasterDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        chmDangerMstId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'info',
      dangerStore: {
        chmDangerMstId: '',
        plantCd: '',
        chmDangerArea: '',
        chmDangerManageUserId: '',
        chmDangerMstName: '',
        chmDangerUserId: '',
        chmDangerSafetyUserId: '',
        chmDangerCount: '',
        chmDangerMainUse: '',
        installPermitDate: '',
        permitNo: '',
        useFlag: 'Y',
        chmDangerTypeCd: null,
        typeList: [],
        deleteTypeList: [],
        itemList: [],
        fireFightings: [],
      },
      editable: true,
      detailUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.chmDangerMstId)
    },
    tabHeight() {
      return String(this.contentHeight - 36);
    },
    tabItems() {
      let _items = [
        { name: 'info', icon: 'info', label: 'LBLBASEINFO', component: () => import(`${'./dangerMasterInfo.vue'}`) },
      ]
      if (this.isOld) {
        _items.push({ name: 'item', icon: 'construction', label: '부가정보(점검항목, 소방시설)', component: () => import(`${'./dangerMasterEtc.vue'}`) })
      }
      return _items;
    }
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.chm.danger.master.get.url;
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.chmDangerMstId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.dangerStore, _result.data)
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      }
    },
    closePopup() {
      this.$emit('closePopup')
    }
  }
};
</script>
